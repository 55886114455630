import React from "react";
import { useState, useEffect } from "react";
import { fetchData } from "./../helpers/data";
import { useNavigate } from "react-router-dom";

import DropdownBrand from "./../components/Form/DropdownBrand";
import DropdownModel from "./../components/Form/DropdownModel";
import DropdownYear from "./../components/Form/DropdownYear";
import FooterCotacao from "../components/Footer/FooterCotacao";

import Header from "./../assets/header-fipe.png";
import { sendData } from "../helpers/sendMoskit";
import sendEmail from "../helpers/sendEmail";

import ReactPixel from "react-facebook-pixel";

export default function Cotacao() {
  useEffect(() => {
    ReactPixel.pageView();
    ReactPixel.trackCustom("NovaCotacao"); // Alterar cotação --- sendEmail e sendMoskit
  }, []);

  const initialValues = {
    name: "",
    email: "Sem email",
    phone: "",
    Valor: "0",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [truck, setTruck] = useState("Sem resposta");

  const onChangeRadio = (e) => {
    setTruck(e.target.value);
  };

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (formValues.brand && formValues.model && formValues.year) {
      fetchData(formValues.brand, formValues.model, formValues.year).then((formValues) => {
        setFormValues({ ...formValues });
      });
    }
  }, [formValues]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const valor = parseFloat(formValues.Valor.replace(/[^\d,]/g, ""));
      let mensalidade = 0.0;
      if (valor === 0) {
        mensalidade = 0;
      } else if (valor <= 100000) {
        mensalidade = 67000;
      } else if (valor <= 150000) {
        mensalidade = 80856;
      } else if (valor <= 200000) {
        mensalidade = 97054;
      } else if (valor <= 250000) {
        mensalidade = 110404;
      } else if (valor <= 300000) {
        mensalidade = 125890;
      } else if (valor <= 350000) {
        mensalidade = 141376;
      } else if (valor <= 400000) {
        mensalidade = 157040;
      } else if (valor <= 450000) {
        mensalidade = 172348;
      } else if (valor <= 500000) {
        mensalidade = 188012;
      } else {
        mensalidade = 100;
      }
      sendEmail(formValues, truck, mensalidade);
      sendData(formValues, truck, mensalidade);
      navigate("/proposta", { state: { formValues, truck, mensalidade } });
    }
  });

  const validade = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "* Campo necessário";
    }
    if (!values.phone) {
      errors.phone = "* Campo necessário";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validade(formValues));
    setIsSubmit(true);
  };

  return (
    <div className="w-screen pt-1 ">
      <div className="bg-slate-300 lg:w-1/2 lg:mt-10 lg:mx-auto m-4  rounded-md overflow-hidden shadow-lg ">
        <div className="bg-black ">
          <img className="w-full" alt="Proteauto e Eficaz" src={Header} />
          <div className="text-center text-white pb-2 text-xl lg:text-2xl">Faça sua cotação!</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 sm:p-6">
              <label htmlFor="brand" className="block text-md font-medium text-gray-700">
                Marca do caminhão:
              </label>
              <DropdownBrand onChange={handleInputChange} />

              <label htmlFor="model" className="block text-md font-medium text-gray-700">
                Modelo do caminhão:
              </label>
              <DropdownModel brand={formValues.brand} onChange={handleInputChange} />

              <label htmlFor="year" className="block text-md font-medium text-gray-700">
                Ano do caminhão: <span className="text-red-500 text-sm">*somente acima de 1981</span>
              </label>
              <DropdownYear brand={formValues.brand} model={formValues.model} onChange={handleInputChange} />

              <label className="block text-md font-medium text-gray-700">Nome : * </label>
              <input
                id="name"
                name="name"
                type="text"
                placeholder="Nome e sobrenome"
                onChange={handleInputChange}
                className="my-1 p-1 bg-slate-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{formErrors.name}</p>

              <label className="block text-md font-medium text-gray-700">Email : </label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="nome@email.com"
                onChange={handleInputChange}
                className="my-1 p-1 bg-slate-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              <label className="block text-md font-medium text-gray-700">Telefone : </label>
              <input
                id="phone"
                name="phone"
                type="text"
                placeholder="(00) 00000-0000"
                onChange={handleInputChange}
                className="my-1 p-1 bg-slate-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <p className="text-sm text-red-600">{formErrors.phone}</p>

              <div>
                <label className="block text-md font-medium text-gray-700">Sobre seu caminhão</label>
                <div className="mt-2 space-y-2">
                  <div className="flex items-center">
                    <input
                      id="1"
                      name="1"
                      type="radio"
                      checked={truck === "Já tenho seguro" ? true : false}
                      onChange={onChangeRadio}
                      value="Já tenho seguro"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="1" className="ml-3 block text-sm font-medium text-gray-700">
                      {" "}
                      Já tenho seguro{" "}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="2"
                      name="2"
                      type="radio"
                      checked={truck === "Não tenho seguro" ? true : false}
                      onChange={onChangeRadio}
                      value="Não tenho seguro"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="2" className="ml-3 block text-sm font-medium text-gray-700">
                      {" "}
                      Não tenho seguro{" "}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="3"
                      name="3"
                      type="radio"
                      checked={truck === "Meu seguro está pra vencer" ? true : false}
                      onChange={onChangeRadio}
                      value="Meu seguro está pra vencer"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="3" className="ml-3 block text-sm font-medium text-gray-700">
                      {" "}
                      Meu seguro está pra vencer{" "}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="4"
                      name="4"
                      type="radio"
                      checked={truck === "Não tenho caminhão" ? true : false}
                      onChange={onChangeRadio}
                      value="Não tenho caminhão"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="4" className="ml-3 block text-sm font-medium text-gray-700">
                      {" "}
                      Não tenho caminhão{" "}
                    </label>
                  </div>
                </div>
              </div>

              <button
                className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-full"
                onClick={scrollToTop}
                type="submit"
              >
                SOLICITAR COTAÇÃO
              </button>
            </div>
          </div>
        </form>
      </div>
      <FooterCotacao />
    </div>
  );
}
