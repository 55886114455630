import axios from "axios";

export function sendData(formValues, truck, mensalidade, motivo) {
  var dayjs = require("dayjs");
  const date = dayjs().toISOString();

  if (formValues.email === "undefined" || formValues.email === "") {
    formValues.email = "Sem e-mail";
  }
  if (formValues.Marca === undefined) {
    formValues.Marca = "Sem marca";
  }
  if (formValues.Modelo === undefined) {
    formValues.Modelo = "Sem modelo";
  }
  if (formValues.AnoModelo === undefined) {
    formValues.AnoModelo = "Sem ano";
  }
  if (formValues.AnoModelo === 32000) {
    formValues.AnoModelo = "Zero";
  }
  if (formValues.Valor === undefined) {
    formValues.Valor = "Sem valor";
  }
  if (truck === undefined) {
    truck = "Sem resposta";
  }
  if (motivo === undefined) {
    motivo = "Sem resposta";
  }

  const options = {
    method: "POST",
    url: "https://api.moskitcrm.com/v2/deals",
    headers: {
      apikey: "0bd11cb2-42b6-4e97-b132-f2b6c8c3f312",
      "Content-Type": "application/json",
      "X-Moskit-Origin": "WEB",
    },
    data: {
      id: 0,
      createdBy: { id: 59727 },
      responsible: { id: 59727 },
      dateCreated: date,
      name: formValues.name,
      source: "WEB",
      origin: "WEB",
      status: "OPEN",
      price: mensalidade,
      entityCustomFields: [
        { id: "CF_dVKmQ5i1CdZOLmWR", textValue: formValues.Marca },
        { id: "CF_A4wMWNigC6NW4qB8", textValue: formValues.Modelo },
        { id: "CF_6rRmweivC67xZq4X", textValue: formValues.AnoModelo },
        { id: "CF_K7Rm8QiRCK6v6DbN", textValue: formValues.Valor },
        { id: "CF_2ojMxLiPCZaK7MOE", textValue: formValues.email },
        { id: "CF_gvGm3Bi0CzRdKM45", textValue: formValues.phone },
        { id: "CF_gvGm3Bi0CzPPGM45", textValue: truck },
        { id: "CF_Rg7MnEiLCA7leqvd", textValue: "NovaCotacao" }, // Alterar cotação --- sendEmail e Cotacao
        { id: "CF_Rg7MnEiLCANo8qvd", textValue: motivo },
      ],
      stage: { id: 178196 },
    },
  };

  axios
    .request(options)
    .then(function (response) {
    })
    .catch(function (error) {
    });
}
